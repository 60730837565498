import React, { useEffect, useState } from "react";
import PageHeader from "../../common/pageheader/pageheader";
import { connect } from "react-redux";
import Loader from "../../common/loader/loader";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { showAlert } from "../../common/sweetalert/sweetalert";
import { Link } from "react-router-dom"
import { encryptData } from "../../../resources/constants";
import { toast } from "react-toastify";


function ModuleEvaluation(props)
{
    const token = props.loginData[0].token;
    const [isFormLoading, setIsFormLoading] = useState('off');
    const [isLoading, setIsLoading] = useState(true);
    const [semesterList, setSemesterList] = useState([])
    const [moduleSemesterList, setModuleSemesterList] = useState([]);
    const [total, setTotal] = useState(0)
    const [selectedModule, setSelectedMdule] = useState({
        ModuleCode: "",
        ModuleName: "",
        semester: ""
    })
    const [formType, setFormType] = useState('attendance')

    const studentID = props.loginData[0].StudentID

    const getSemesters = async () =>
    {
        await axios.get(`${serverLink}students/registration/module/semester/list`, token)
            .then((result) =>
            {
                if (result.data.length > 0)
                {
                    setSemesterList(result.data)
                }
                setIsLoading(false)
            })
    }

    const getData = async (sem) =>
    {
        axios.post(`${serverLink}students/registration/module/evaluation`, { studentID: studentID, SemesterCode: sem }, token).then((result) =>
        {
            if (result.data.length > 0)
            {
                setModuleSemesterList(result.data)
                setTotal(result.data.map(e => parseInt(e.CreditLoad)).reduce((a, b) => a + b, 0))
            } else
            {
                setModuleSemesterList([])
                toast.error("Sorry you don't have any registered module in " + sem + " semester")
            }
            setIsLoading(false)
        })
    }

    useEffect(() =>
    {
        getSemesters();
    }, [])

    const onEdit = (e) =>
    {
        setSelectedMdule({
            ...selectedModule,
            [e.target.id]: e.target.value
        })

        getData(e.target.value);


    }

    return isLoading ? (
        <Loader />
    ) : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Modules Evaluation"}
                items={["Student Registration", "Registration", "Modules Evaluation"]}
            />

            <div className="flex-column-fluid">
                <div className="card">
                    <div className="col-md-12 mt-4 p-5">
                        <select id="semester" onChange={onEdit}
                            value={selectedModule.semester}
                            className="form-select"
                            data-kt-select2="true"
                            data-placeholder="Select option"
                            data-dropdown-parent="#kt_menu_624456606a84b" data-allow-clear="true">
                            <option value={""}>-select semester-</option>
                            {semesterList.length > 0 ?
                                <>
                                    {semesterList.map((x, y) =>
                                    {
                                        return (
                                            <option key={y} value={x.SemesterCode}>Semester {y + 1} ({x.SemesterCode})</option>
                                        )
                                    })}
                                </>
                                :
                                <></>}
                        </select>
                    </div>
                    <div className="card-body pt-0">
                        <div className="table-responsive" style={{ overflow: "auto" }}>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <td><strong>S/NO</strong></td>
                                        <td><strong>Module Code</strong></td>
                                        <td><strong>Module Description</strong></td>
                                        <td><strong>Credit Load</strong></td>
                                        <td><strong>School Semester</strong></td>
                                        <td><strong>Rate</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        moduleSemesterList.length > 0 ?
                                            <>
                                                {
                                                    moduleSemesterList.map((x, y) =>
                                                    {
                                                        return (

                                                            <tr key={y}>
                                                                <td>{y + 1}</td>
                                                                <td>{x.ModuleCode}</td>
                                                                <td>{x.ModuleName}</td>
                                                                <td>{x.CreditLoad}</td>
                                                                <td>{x.SemesterCode}</td>
                                                                <td>{
                                                                    x.IsLecturerRated === 0 ?
                                                                        <Link className="btn btn-sm btn-primary" to={`/registration/staff-ratings/${encryptData(x.EntryID + "-" + x.SemesterCode + "-" + x.ModuleCode)}`}>
                                                                            Rate</Link> : '--'
                                                                }</td>

                                                            </tr>



                                                        )
                                                    })
                                                }
                                            </>
                                            : <></>

                                    }
                                    <tr>
                                        <td>-</td>
                                        <td>-</td>

                                        <td><strong>Total Credit Registered</strong></td>
                                        <td><strong>
                                            {
                                                total
                                            }</strong>
                                        </td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) =>
{
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(ModuleEvaluation);
