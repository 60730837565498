import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { serverLink } from "../../resources/url";
import Loader from "../common/loader/loader";
import Modal from "../common/modal/modal";
import PageHeader from "../common/pageheader/pageheader";
import Table from "../common/table/table";
import { Link } from "react-router-dom"
import { showAlert } from "../common/sweetalert/sweetalert";


function SemesterResult(props)
{
    const token = props.loginData[0].token;
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setIsFormLoading] = useState('off');
    const columns = ["sn", "ModuleCode", "ModuleDescription", "Credit", "Grade", "CA", "Exam", "Total", "Level", "Semester", "StudentSemester", "Action"];
    const [tableData, setTableData] = useState([]);

    const studentID = props.loginData[0].StudentID;
    const [datatable, setDatatable] = useState({
        columns: [
            {
                label: "S/N",
                field: "sn",
            },
            {
                label: "ModuleCode",
                field: "ModuleCode",
            },
            {
                label: "ModuleDescription",
                field: "ModuleTitle",
            },
            {
                label: "Credit",
                field: "CreditUnit",
            },
            {
                label: "Grade",
                field: "StudentGrade",
            },
            {
                label: "CA",
                field: "CAScore",
            },
            {
                label: "Total",
                field: "Total",
            },
            {
                label: "Level",
                field: "StudentLevel",
            },
            {
                label: "SemesterCode",
                field: "SemesterCode",
            },
            {
                label: "StudentSemester",
                field: "StudentSemester",
            },
            {
                label: "Complaint",
                field: "action",
            },

        ],
        rows: [],
    });

    const [resultComplain, setResultComplain] = useState({
        ModuleCode: "",
        EntryID: "",
        ModuleTitle: "",
        SemesterCode: "",
        SelectedSemesterCode: "",
        ComplaintType: "",
        ComplaintMessage: "",
        InsertedBy: props.loginData[0].StudentID,
        TotalCreditUnit: ""

    })
    const [semeterList, setsemesterList] = useState([]);
    const [data, setData] = useState([]);
    const [totalCU, setTotalCU] = useState([]);
    const [viewResult, setViewResult] = useState([]);
    const [checkResults, setCheckResults] = useState([]);

    const getSemesters = async () =>
    {
        await axios.get(`${serverLink}students/registration/module/semester/list`, token)
            .then((result) =>
            {
                if (result.data.length > 0)
                {
                    setsemesterList(result.data)
                }
                setIsLoading(false)
            })
    }

    const getResults = async (semester) =>
    {

        await axios.post(`${serverLink}students/registration/check/student/allow/to/view/result/${semester}`, { studentID: studentID }, token).then((result) =>
        {
            if (result.data.length > 0)
            {
                setViewResult(result.data)
            } else
            {
                setViewResult([])
            }
        })

        let total_ = [];
        await axios.post(`${serverLink}students/registration/module/result/total-credit-unit/${semester}`, { StudentID: studentID }, token)
            .then(async (result) =>
            {
                if (result.data.length > 0)
                {
                    const total_cu = await result.data.map(e => parseFloat(e.totalCreditUnit)).reduce((a, b) => a + b, 0);
                    setTotalCU(total_cu)
                } else
                {
                    setTotalCU(0)
                }
            }).catch((e) =>
            {
                console.log(e)
            })

        await axios.post(`${serverLink}students/registration/module/result/list/${semester}`, { StudentID: studentID }, token)
            .then((result) =>
            {
                if (result.data.length > 0)
                {
                    getTableData(result.data)
                }
            })
            .catch((err) =>
            {
                console.log("NETWORK ERROR");
            });
    };
    const getTableData = (rows__) =>
    {
        let rows = [];
        rows__.length > 0 &&
            rows__.map((item, index) =>
            {
                rows.push({
                    sn: index + 1,
                    ModuleCode: item.ModuleCode,
                    ModuleTitle: item.ModuleTitle,
                    CreditUnit: item.CreditUnit,
                    CAScore: item.IsLecturerRated === 0 ? "--" : item.CAScore,
                    ExamScore: item.IsLecturerRated === 0 ? "--" : item.ExamScore,
                    Total: item.IsLecturerRated === 0 ? "--" : item.Total,
                    StudentLevel: item.StudentLevel,
                    StudentGrade: item.IsLecturerRated === 0 ? "--" : item.StudentGrade,
                    Decision: item.Decision,
                    SemesterCode: item.SemesterCode,
                    StudentSemester: item.StudentSemester,
                    action: (
                        item.IsLecturerRated === 0 ? "--" :
                            <button
                                className="btn btn-sm btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_general"
                                onClick={() =>
                                {
                                    setResultComplain({
                                        ...resultComplain,
                                        EntryID: item.EntryID,
                                        ModuleCode: item.ModuleCode,
                                        SemesterCode: item.SemesterCode,
                                        StudentSemester: item.StudentSemester,
                                        ModuleTitle: item.ModuleTitle,
                                        CAScore: item.CAScore,
                                        ExamScore: item.ExamScore,
                                        StudentSemester: item.StudentSemester,

                                    })
                                }}
                            >
                                <i className="fa fa-pen" />
                            </button>
                    ),

                });
            });
        setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows__.length > 0 ? rows : [],
        });
        setIsLoading(false);
    }

    const onSubmit = async (e) =>
    {
        e.preventDefault();
        if (resultComplain.ComplaintType.trim() === "")
        {
            showAlert("EMPTY FIELD", "Please choose Complaint Type", "error");
            return false;
        }
        try
        {
            await axios.post(`${serverLink}students/registration/result/complaint/add`, resultComplain)
                .then((result) =>
                {
                    if (result.data.message === "success")
                    {
                        toast.success('complaint lodges successfully, we would contact you via email');
                        setResultComplain({
                            ...resultComplain,
                            ModuleCode: "",
                            EntryID: "",
                            ModuleTitle: "",
                            SemesterCode: "",
                            SelectedSemesterCode: "",
                            ComplaintType: "",
                            ComplaintMessage: ""
                        })
                        document.getElementById("closeModal").click();
                    } else
                    {
                        toast.error('please try again...')
                    }
                })
        } catch (e)
        {
            console.log('NETWROK ERROR')
        }

    }

    const onEdit = (e) =>
    {
        setResultComplain({
            ...resultComplain,
            [e.target.id]: e.target.value
        })

        getResults(e.target.value);


    }

    useEffect(() =>
    {
        getSemesters();
    }, []);

    return isLoading ? (
        <Loader />
    ) : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Student Result"}
                items={["Student Result", "Semester Result"]}
            />
            <div className="text-center alert alert-info"> NB: You can't see your grade and score without completing lecturer's evaluation. To start your evaluation please click here. <Link to='/registration/module-evaluation'>here</Link></div>
            <div className="flex-column-fluid">
                <div className="card">
                    <div className="card-body pt-0">
                        <div className="col-md-12 mt-4">
                            <select id="SelectedSemesterCode" onChange={onEdit}
                                value={resultComplain.SelectedSemesterCode}
                                className="form-select"
                                data-kt-select2="true"
                                data-placeholder="Select option"
                                data-dropdown-parent="#kt_menu_624456606a84b" data-allow-clear="true">
                                <option value={""}>-select semester-</option>
                                {semeterList.length > 0 ?
                                    <>
                                        {semeterList.map((x, y) =>
                                        {
                                            return (
                                                <option key={y} value={x.SemesterCode}>Semester {y + 1} ({x.SemesterCode})</option>
                                            )
                                        })}
                                    </>
                                    :
                                    <></>}
                            </select>
                        </div>
                    </div>

                    {

                        resultComplain.SelectedSemesterCode !== "" ?
                            viewResult.length > 0 ?
                                <>
                                    <Table data={datatable} />
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><strong>Total Registered Credit Unit: </strong><span className="fs-1"> {totalCU}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </>
                                :
                                <p className="alert alert-danger text-center">Sorry you don't have permission to view result, please clear with finance. contact ---- or go to finance office at ----</p>
                            :
                            <p className="alert alert-danger text-center">Select Semester to View Result</p>
                    }
                </div>
            </div>
            <Modal title={"Result Complaint: " + resultComplain.ModuleTitle + "  " + resultComplain.ModuleCode} >
                <form>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <label className="fw-bolder fs-6">CA: {resultComplain.CAScore}</label>
                            </div>
                            <div className="col-md-6 mb-2">
                                <label className="fw-bolder fs-6">Exam: {resultComplain.ExamScore}</label>
                            </div>
                            <div className="col-md-6">
                                <label className="fw-bolder fs-6">Semester: {resultComplain.SemesterCode}</label>
                            </div>
                            <div className="col-md-6">
                                <label className="fw-bolder fs-6">StudentSemester: {resultComplain.StudentSemester}</label>
                            </div>
                            <div className="col-md-12 mt-4">
                                <label className="fw-bolder fs-6">Item missing</label>
                            </div>
                            <div className="col-md-4 mt-10">
                                <label className="fw-bolder fs-6">
                                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                        Exam:&nbsp;<input className="form-check-input" id="ComplaintType" onChange={onEdit} type="radio" name="ComplaintType" value={"missingExam"} />
                                    </div>
                                </label>
                            </div>
                            <div className="col-md-4 mt-10">
                                <label className="fw-bolder fs-6">
                                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                        CA:&nbsp;<input className="form-check-input" id="ComplaintType" onChange={onEdit} type="radio" name="ComplaintType" value={"missingCA"} />
                                    </div>
                                </label>
                            </div>
                            <div className="col-md-4 mt-10">
                                <label className="fw-bolder fs-6">
                                    <div className="form-check form-check-custom form-check-solid form-check-primary me-6">
                                        Both:&nbsp;<input className="form-check-input" id="ComplaintType" onChange={onEdit} type="radio" name="ComplaintType" value={"Both"} />
                                    </div>
                                </label>
                            </div>

                        </div>
                        <div className="form-group mt-4">
                            <label className="fs-6">Remarks</label>
                            <textarea className="form-control" cols={'10'} value={resultComplain.ComplaintMessage} rows="5" onChange={onEdit} id="ComplaintMessage"></textarea>
                        </div>
                        <div className="form-group mt-5">
                            <button type="submit" className="btn btn-sm btn-primary" onClick={onSubmit}>
                                Submit complain
                            </button>
                        </div>
                    </div>
                </form>

            </Modal>
        </div>
    );
}

const mapStateToProps = (state) =>
{
    return {
        loginData: state.LoginDetails,
    };
};

export default connect(mapStateToProps, null)(SemesterResult);

